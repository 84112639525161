<template>
  <v-container fluid>
    <ReportToolbar
      class="white mx-0"
      @update="filterChanged" />
    <v-col
      cols="12"
      v-if="loading"
      class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
    <template v-else>
      <v-data-table
        :headers="orderTableHeaders"
        :items="report.orders"
        item-key="index">
        <template v-slot:item="props">
          <tr
            class="-clickable"
            :class="{'red lighten-4': props.item.storno}"
            @click="selectOrder(props.item.id)">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.total | euro }} {{ getPaymentTypeText(props.item) }}</td>
            <td>{{ props.item.orderProductTotal | euro }}</td>
            <td>{{ props.item.productNames }} ({{ props.item.provision | euro }})</td>
            <td>{{ props.item.delivery_costs | euro }}</td>
            <td>{{ props.item.customerName }}</td>
            <td>{{ props.item.companyName }}</td>
            <td>{{ props.item.order_start | date }}</td>
            <td>{{ props.item.order_end | date }}</td>
            <td>{{ props.item.days }}</td>
            <td>{{ props.item.accepted_at | datetime }}</td>
            <td>{{ props.item.storno | datetime }}</td>
            <td>
              <div
                v-for="payment in props.item.payments"
                :key="payment">
                {{ payment }}
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table
        :headers="totalTableHeaders"
        :items="total"
        item-key="index"
        class="mt-6"
        hide-default-footer>
        <template v-slot:item="props">
          <tr>
            <td>{{ props.item.orderCount }}</td>
            <td>{{ props.item.orderSum | euro }}</td>
            <td>{{ props.item.provisionSum | euro }}</td>
            <td>{{ props.item.ccSum | euro }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
    <template v-if="isSuperAdmin">
      <h3>Daten Exporte</h3>
      <div class="row">
        <div class="col-12">
          <v-btn
            @click="downloadReport">
            Statistik export (Excel)
          </v-btn>
        </div>
        <div class="col-12">
          <v-btn
            @click="downloadRentPrices">
            Individuelle Mietpreise (Excel)
          </v-btn>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import ReportToolbar from '@/components/report/ReportToolbar.vue'
import ReportAPI from '@/api/reports'
import { mapGetters } from 'vuex'
import { downloadXLSX } from '@/logic/helpers'
import PricesAPI from '@/api/prices'

export default {
  data() {
    return {
      loading: false,
      options: null,
      report: {},
      orderTableHeaders: [
        {
          text: 'Id',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Auftragskosten',
          sortable: true,
          value: 'total',
        },
        {
          text: 'Mietkosten',
          sortable: false,
        },
        {
          text: 'Produkte',
          sortable: true,
          value: 'provision',
        },
        {
          text: 'Lieferung',
          sortable: true,
          value: 'delivery_costs',
        },
        {
          text: 'Kunde',
          sortable: true,
          value: 'customerName',
        },
        {
          text: 'Partner',
          sortable: false,
          value: 'companyName',
        },
        {
          text: 'Auftragsbeginn',
          sortable: false,
          value: 'order_start',
        },
        {
          text: 'Auftragsende',
          sortable: false,
          value: 'order_end',
        },
        {
          text: 'Miettage',
          sortable: false,
          value: 'days',
        },
        {
          text: 'Akzeptiert am',
          sortable: true,
          value: 'accepted_at',
        },
        {
          text: 'Storniert am',
          sortable: true,
          value: 'storno',
        },
        {
          text: 'CC Zahlungen',
          sortable: false,
          value: 'payments',
        },
      ],
      totalTableHeaders: [
        {
          text: 'Anzahl',
          sortable: true,
          value: 'orderCount',
        },
        {
          text: 'Auftragskosten',
          sortable: true,
          value: 'orderSum',
        },
        {
          text: 'Provision Netto (Stornos sind mit einbezogen)',
          sortable: true,
          value: 'provisionSum',
        },
        {
          text: 'CC Zahlungen',
          sortable: true,
          value: 'ccSum',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
    }),
    total() {
      let orserCount = 0

      if (this.report.orders) {
        orserCount = this.report.orders.length
      }

      return [
        {
          orderCount: orserCount,
          orderSum: this.report.orderSum,
          ccSum: this.report.ccSum,
          provisionSum: this.report.provisionSum,
        },
      ]
    },
  },
  methods: {
    filterChanged(options) {
      this.options = options
      this.loading = true
      ReportAPI.getOrderProvisionReport(options.from, options.until, options.partners).then((response) => {
        this.report = response
        this.loading = false
      })
    },
    getPaymentTypeText(order) {
      switch (order.payment_type) {
        case this.$constants.PAYMENT_TYPES.PAYMENT_STRIPE:
          return 'Kreditkarte'
        case this.$constants.PAYMENT_TYPES.PAYMENT_CASH:
        default:
          return 'Bar / EC'
      }
    },
    selectOrder(id) {
      const route = this.$router.resolve({
        name: 'Auftrag',
        params: {
          id,
        },
      })
      window.open(route.href, '_blank')
    },
    downloadReport() {
      ReportAPI.exportOrderProvisionReport(this.options.from, this.options.until, this.options.partners).then((response) => {
        downloadXLSX(response, `mh-export-${this.options.from}-bis-${this.options.until}.xlsx`)
      })
    },
    downloadRentPrices() {
      PricesAPI.exportAllPrices().then((response) => {
        downloadXLSX(response, 'alle-mietpreise.xlsx')
      })
    },
  },
  components: {
    ReportToolbar,
  },
}
</script>
